<template>
  <div class="custom-table">
    <a-table
      v-bind="$attrs"
      class="gx-table-responsive"
      :rowKey="rowKey"
      :size="size"
      :rowSelection="rowSelection"
      :dataSource="dataSource"
      :pagination="rePagination"
      @change="handleTableChange"
      :onSelectAll="onSelectAll"
      :columns="columns"
      :loading="loading"
      :rowClassName="getRowClassName"
    >
      <template v-for="(index, name) in $slots" v-slot:[name]="data">
        <slot :name="name" v-bind="data" />
      </template>
    </a-table>
  </div>
</template>
<script>
import { message } from "ant-design-vue";

const PAGINATION_TYPE = {
  NUMBERS: "numbers",
  ARROWS: "arrows",
};

export default {
  name: "StandardTable",
  props: {
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    columns: {
      type: Array,
      default: function () {
        return [];
      },
    },
    fetchService: {
      type: Function,
      default: null,
    },
    disabledDefaultFetchRequest: {
      type: Boolean,
      default: false,
    },
    onSelectAll: {
      type: Function,
      default: null,
    },
    onSelectRow: {
      type: Function,
      default: null,
    },
    rowKey: {
      type: [String, Function],
      default: "id",
    },
    size: {
      type: String,
      default: "default",
    },
    selection: {
      type: Boolean,
      default: false,
    },
    handleShowTotal: {
      type: [Function, Boolean, null, undefined],
      default: null,
    },
    rowClassName: {
      type: String,
      default: null,
    },
    initialValues: {
      type: Object,
      default: () => ({}),
    },
    paginate: {
      type: Boolean,
      default: true,
    },
    showQuickJumper: {
      type: Boolean,
      default: false,
    },
    showSizeChanger: {
      type: Boolean,
      default: false,
    },
    pageSizeOptions: {
      type: Array,
      default: function () {
        return ["10", "20", "50", "100", "200"];
      },
    },
    selectedRowsKey: {
      type: Array,
      default: function () {
        return [];
      },
    },
    defaultFilters: {
      type: Object,
      default: function () {
        return {};
      },
    },
    paginationType: {
      type: String,
      in: [PAGINATION_TYPE.NUMBERS, PAGINATION_TYPE.ARROWS],
      default: PAGINATION_TYPE.NUMBERS,
    },
  },
  data() {
    return {
      hasError: false,
      errorMessage: "",
      isLoading: false,
      dataSource: this.data || [],
      selectedRowKeys: this.selectedRowsKey || [],
      pagination: {
        current: this.initialValues?.page || 1,
        pageSizeOptions: this.pageSizeOptions,
        showSizeChanger: this.showSizeChanger,
        showQuickJumper: this.showQuickJumper,
        defaultCurrent: 1,
        defaultPageSize: this.initialValues?.limit || 10,
        pageSize: this.initialValues?.limit || 10,
        position: "bottom",
        total: 0,
      },
      filters: this.initialValues?.filters || {},
      sorter: this.initialValues?.sorter || {},
      search: this.initialValues?.search || "",
    };
  },
  methods: {
    handleResponse(response) {
      try {
        if (response) {
          const dataSource = response.data.data;
          const recordsFiltered = response ? response.data.recordsFiltered : 1;
          this.dataSource = dataSource;
          this.pagination = {
            ...this.pagination,
            total: recordsFiltered,
          };
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      } catch (err) {
        message.error(err.message || "Unknown Error");
      }
    },
    fetchData(request) {
      if (this.fetchService) {
        this.isLoading = true;
        this.fetchService(request)
          .then((response) => {
            if (response) {
              const dataSource = response.data.data || [];
              const recordsFiltered = response
                ? response.data.recordsFiltered
                : 1;
              this.dataSource = dataSource;
              this.pagination = {
                ...this.pagination,
                total: recordsFiltered,
              };
              this.isLoading = false;
              this.handleResponse(response);
            }
          })
          .catch((err) => {
            this.isLoading = false;
            message.error(err.message || "Unknown Error");
          });
      }
    },
    generatedPagination(pagination, filters, sorter) {
      let { columnKey, order } = sorter;
      let orderBy = order === "ascend" ? "asc" : "desc";
      let sorterResult = columnKey && order ? { [columnKey]: orderBy } : {};
      let newFilters = { ...filters, ...this.filters, ...this.defaultFilters };
      let search = this.search;
      this.pagination = pagination;
      this.filters = newFilters;
      this.sorter = sorterResult;
      this.search = search;
      return {
        search: search,
        page: pagination.current,
        limit: pagination.pageSize,
        filters: {
          ...newFilters,
          sortBy: sorterResult,
        },
      };
    },
    handleFetch(request) {
      if (this.fetchService) {
        this.fetchData(request);
      }
    },
    handleRequest() {
      let request = this.generatedPagination(
        this.pagination,
        this.filters,
        this.sorter,
        []
      );
      this.handleFetch(request);
    },
    handleSearchWithFilters(search, filters = {}) {
      this.search = search;
      this.filters = {
        ...this.filters,
        ...filters,
      };
      this.handleRequest();
    },
    handleSearch(search) {
      this.pagination.current = 1;
      this.search = search;
      this.handleRequest();
    },
    handleSorter(column, order) {
      this.sorter[column] = order;
    },
    handleFilters(filters) {
      this.filters = {
        ...this.filters,
        ...filters,
      };
      this.handleRequest();
    },
    handleFilter(key, value) {
      this.filters[key] = value;
      this.handleRequest();
    },
    handleSelectAll(selected, selectedRows, changeRows) {
      if (this.onSelectAll) {
        this.onSelectAll(selected, selectedRows, changeRows);
      }
    },
    handleTableChange(pagination, filters, sorter, extra) {
      const request = this.generatedPagination(
        pagination,
        filters,
        sorter,
        extra
      );
      this.handleFetch(request);
    },
    handleRowSelect(selectedRowKeys, selectedRows) {
      if (this.onSelectRow) {
        this.onSelectRow(selectedRows);
      }
      this.selectedRowKeys = selectedRowKeys;
    },
    showTotal(total, range) {
      return `${range[0]}-${range[1]} of ${total} items`;
    },
    getRowClassName(record, index) {
      return index % 2 !== 0 ? this.rowClassName || "row-dark-color" : "";
    },
  },
  computed: {
    rowSelection() {
      const rowSelection = Object.assign(
        {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.handleRowSelect,
          onSelectAll: this.handleSelectAll,
          getCheckboxProps: (record) => ({
            props: {
              disabled: record.disabled,
            },
          }),
        },
        this.$attrs.rowSelection || {}
      );
      return this.selection ? rowSelection : undefined;
    },
    rePagination() {
      let { pageSizeOptions, defaultPageSize, defaultCurrent, position } =
        this.pagination;
      return this.paginate
        ? {
            pageSizeOptions: pageSizeOptions,
            defaultPageSize: defaultPageSize,
            defaultCurrent: defaultCurrent,
            ...([undefined, null, false].includes(this.handleShowTotal)
              ? {}
              : { showTotal: this.handleShowTotal || this.showTotal }),
            position: this.position || position,
            ...(this.paginationType === PAGINATION_TYPE.ARROWS
              ? {
                  size: "small",
                  showLessItems: true,
                  itemRender: (_, type, originalElement) => {
                    switch (type) {
                      case "prev":
                      case "next":
                        return originalElement;
                      default:
                        return <span class="hidden"></span>;
                    }
                  },
                }
              : {}),
            ...this.pagination,
          }
        : false;
    },
    loading() {
      return {
        spinning: this.isLoading,
        size: "large",
        tip: `Cargando...`,
      };
    },
  },
  created() {
    if (!this.disabledDefaultFetchRequest) {
      this.handleRequest();
    }
  },
  watch: {
    selectedRowsKey: {
      handler(val) {
        this.selectedRowKeys = val;
      },
      immediate: true,
    },
    data: {
      handler(val = []) {
        this.dataSource = val;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss">
.custom-table {
  .row-dark-color {
    background-color: #fafafa !important;
  }

  .ant-pagination {
    &-item {
      &:has(> .hidden) {
        display: none;
      }
    }
    &-jump-prev,
    &-jump-next {
      &:has(> .hidden) {
        display: none;
      }
    }

    &.mini {
      .ant-pagination-prev,
      .ant-pagination-next {
        .ant-pagination-item-link {
          border-radius: 0;
          border: none;
          background-color: transparent;
          .anticon {
            font-size: 14px;
            vertical-align: middle;
          }
          &:hover,
          &:focus {
            color: color(link);
          }
        }
      }
    }
  }
}
</style>
